import andrew from '../assets/andrew.png';
import ajay from '../assets/ajay.png';
import eiran from '../assets/eiran.png';
import jennifer from '../assets/jennifer.png';

const users = [
  {
    name: 'Andrew J. Sauer',
    avatar: andrew,
    location: "Saint Luke's Mid- America Heart Institute, Kansas City, MO",
    title: 'MD',
    about: `Dr. Andrew Sauer is a cardiologist specializing in heart failure, cardiac transplantation, and mechanical circulatory support. Dr. Sauer joined Saint Luke’s from the University of Kansas Health System, where he was an Associate Professor and Chief of Heart Failure Therapies Division. He was recruited by the University of Kansas Health System (KU) in 2015 to lead the birth of a premier heart failure therapies and cardiactransplant program. Over five years he has grown his team to more than 50 staff—a team that has performed more than 150 ventricular assist device implantations and more than 100 heart transplants with nation-leading quality of outcomes. Dr. Sauer’s passion in Kansas has further evolved toward exploring rural health care disparities and using remote monitoring technologies to supplement telemedicine (NIH funding for these efforts is pending and will commence 2021); he is a champion for bridging the divide that disadvantages many patients in rural America. He has taken a nation-leading role for major clinical trials such as GUIDE-HF, PREEMPT-HF, TRILUMINATE, VENUS-HF and many others in the HF device space. In 2020, he joined the editorial board as Associate Editor for Journal of Cardiac Failure,the official journal of the Heart Failure Society of America (HFSA).`,
    program: 'University of Kansas, Kansas City, KS',
    twitter: '@AndrewJSauer',
  },
  {
    name: 'Ajay V. Srivastava',
    avatar: ajay,
    title: 'MD',
    about: ` is an advanced heart failure cardiologist with a special interest in mechanical circulatory support and heart failure population health. His current roles at Scripps include being an integral member of the Cardiogenic Shock, LVAD and ECMO programs as well as being the physician leader for Heart Failure population health at the system level. His clinical interests included the medical management of chronic heart failure, pulmonary venous hypertension, LVAD, cardiac sarcoidosis and exercise hemodynamics evaluation. Dr. Srivastava has a keen interest in delivery and implementation of heart failure therapies across cardiac sub-specialties both at Scripps and across the larger community. Earlier this year, he joined the editorial board of Journal of Cardiac Failure, the official journal of the Heart Failure Society of America (HFSA).`,
    program: 'Scripps Clinic, La Jolla, CA',
    twitter: '@ajaysmd',
    location: 'Scripps Clinic, San Diego, CA',
  },
  {
    name: 'Eiran Z. Gorodeski',
    avatar: eiran,
    title: 'MD, MPH',
    about: ` MD, MPH, FACC, FHFSA, is Medical Director of the Advanced Heart Failure & Transplant Center at the Harrington Heart & Vascular Institute, University Hospitals in Cleveland, Ohio. He is the Program Director of his institution’s AHFTC accredited and non-accredited programs. He is also a Professor of Medicine at Case Western Reserve University. Program: Harrington Heart & Vascular Institute, University Hospitals, Cleveland, OH`,
    program: 'University Hospitals Harrington Heart & Vascular Institute, Cleveland, OH',
    twitter: '@EiranGorodeski ',
    location: 'University Hospitals, Cleveland, OH',
  },
  {
    name: 'Jennifer Cowger',
    avatar: jennifer,
    title: 'MD, MS',
    about: `is Medical Director of the Mechanical Circulatory Support Program and Co-Director of the Cardiac Critical Care Unit at Henry Ford Hospitals. Dr. Cowger focuses on caring for patients with severe heart failure, as well as those who have had — or are being considered for — a heart transplant or a heart left ventricular assist device (LVAD). She also specializes in cardiac sarcoidosis and cardiac amyloidosis. To ensure her patients have access to the latest therapies, she participates in clinical research and is an active member of prominent cardiac societies such as- American College of Cardiology, the Heart Failure Society of American, and the International Society for Heart and Lung Transplant.`,
    program: '',
    twitter: '@preventfailure',
    location: 'Henry Ford Hospital, Detroit, MI',
  },
];

export default users;
