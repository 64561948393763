import React from "react";
import styled from 'styled-components';

import SectionTitle from '../common/SectionTitle';
import { keyQuestions, objectives } from "../../data/proposalDetails";

const Container = styled.div`
  margin-top: 80px;
`

const Text = styled.div`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 10px;
  @media screen and (max-width: 550px) {
    text-align: justify;
  }
  @media screen and (min-width: 550px) {
    text-align: justify;
  }
`;

const SubTitle = styled.span`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
  color: #303030;
`;

const OrderedList = styled.ol`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  list-style-position: inside;
  @media screen and (min-width: 550px) {
    margin-left: 30px;
  }
`

const CallsForProposals = () => {
  return (
    <Container>
      <SectionTitle backgroundText=''>Call For Proposals - Submission has closed for new proposals</SectionTitle>
      <Text>
        We seek speakers to address the above problem statement and key questions below,
        expanding on present issues faced by trainees; gaps in the professional field leading to
        attrition by trainees; and/or gaps in training leading indifferent engagement of HF
        specialists by institutions/practices. Your proposal should be summarized in 280
        characters or less. The format will be a rapid-fire moderated discussion designed to
        allow for learning and debate. Presentations will be 5 minutes in length, followed by 5
        minutes of moderated discussion that will be informed by simultaneous
        questions/discussion happening in chat and on social media.
      </Text>
      <div>
        <SubTitle>Key Questions:</SubTitle>
        <OrderedList>
          {keyQuestions.map((questions, i) => (
            <li key={i} >{questions}</li>
          ))}
        </OrderedList>
      </div>
      <div>
        <SubTitle>Objectives:</SubTitle>
        <OrderedList>
          {objectives.map((objective, i) => (
            <li key={i}>{objective}</li>
          ))}
        </OrderedList>
      </div>
    </Container>
  )
}

export default CallsForProposals
