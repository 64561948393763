import calendarIcon from '../assets/calendar-white.svg';
import clockIcon from '../assets/time-white.svg';

const scheduleDetails = [
  {
    img: calendarIcon,
    text: 'February 16th, 2023',
  },
  {
    img: clockIcon,
    text: '7-9 PM CST',
  },
];

export default scheduleDetails;
