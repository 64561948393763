import React from 'react';
import FoldContainer from '../common/FoldContainer';
import SectionTitle from '../common/SectionTitle';
import styled, { css } from 'styled-components';
import partnersData from '../../data/partnersData';

interface Props {}

const Image = styled.img<{ isIshi?: string }>`
  @media screen and (max-width: 550px) {
    width: 200px;
  }
  @media screen and (min-width: 550px) {
    width: 250px;
  }
  @media screen and (min-width: 800px) {
    width: 250px;
  }
  ${(props) =>
    props.isIshi === 'true' &&
    css`
      @media screen and (max-width: 550px) {
        height: 50px;
        width: auto;
        margin: 30px 0;
      }
      @media screen and (min-width: 550px) {
        height: 50px;
        width: auto;
        margin: 30px 50px;
      }
      @media screen and (min-width: 800px) {
        height: 60px;
        width: auto;
      }
      margin: 10px 50px;
    `}
`;

const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  @media screen and (max-width: 550px) {
    margin-bottom: 20px;
    width: 100%;
    flex-direction: column;
  }
  @media screen and (min-width: 550px) {
    flex-direction: column;
  }
  @media screen and (min-width: 1100px) {
    flex-direction: row;
  }
  @media screen and (min-width: 1300px) {
    flex-direction: row;
  }
`;

const PartnersSection: React.FC<Props> = () => {
  return (
    <FoldContainer>
      <SectionTitle backgroundText="partners">Our Partners</SectionTitle>
      <ImagesContainer>
        {partnersData.map((el) => (
          <a key={el.alt} target="_blank" href={el.uri}>
            <Image isIshi={el.alt === 'ishi logo' ? 'true' : 'false'} src={el.img} alt={el.alt} />
          </a>
        ))}
      </ImagesContainer>
    </FoldContainer>
  );
};

export default PartnersSection;
