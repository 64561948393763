import React from 'react';
import FoldContainer from '../common/FoldContainer';
import SectionTitle from '../common/SectionTitle';
import styled from 'styled-components';
import EventDetailsCard from '../EventDetailsCard/EventDetailsCard';

interface Props { }

const Text = styled.div`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 10px;
  @media screen and (max-width: 550px) {
    text-align: justify;
  }
  @media screen and (min-width: 550px) {
    text-align: justify;
  }
`;

const ProblemTitle = styled.span`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
  color: #303030;
`;

const AboutSection: React.FC<Props> = () => {
  return (
    <FoldContainer>
      <SectionTitle backgroundText="Event">About the event</SectionTitle>
      <Text>
        The purpose of this symposium is to explore the present state of Heart Failure (HF)
        training and immediate needs of the medical community from HF specialists. Virtual
        round table discussions involving a diverse panel of clinicians, including HF program
        directors and others who care for patients with HF and complex cardiomyopathies, will
        be undertaken to identify 3-5 high level HF training aims. Workforces will be created
        around these aims with the overall goal of enhancing the educational toolset provided to
        community and academic medical centers and practices who care for patients with HF
        and complex cardiomyopathies.
      </Text>
      <Text>
        This virtual symposium will be offered free of charge. While many participants will be
        invited guests, general registration is available and you will receive a confirmation link
        once your application is reviewed and your spot is confirmed. We have limited
        participation to 200 participations.
      </Text>
      <Text>
        <ProblemTitle>The Problem:</ProblemTitle> The field of Advanced Heart Failure and Transplant
        Cardiology (AHFTC) is failing to attract trainees.
      </Text>
      <Text>
        Per 2022 AHA Statistics the &quot;prevalence of HF in the US is projected to increase by
        46% from 2012 to 2030, affecting &gt;8 million adults.&quot; Despite increasing disease burden
        in the population, as well as emerging diagnostic and therapeutic complexities involving
        multiple stakeholders including Heart Failure disease specialists, AHFTC as a career
        has been met with continually waning interest among cardiology trainees. In 2022 only
        69 individuals matched into AHFTC fellowships in the United States, a decreasing trend
        from prior years (n=75 in 2021, and n=80 in 2020). As compared to cardiovascular
        disease (1.5 applicants per position) and electrophysiology (1.0 applicants per position)
        fellowships, interest in AHFTC fellowship training is low (0.6 applicants per position).
      </Text>
      <EventDetailsCard />
    </FoldContainer>
  );
};

export default AboutSection;
