import React from 'react';
import styled from 'styled-components';
import FoldContainer from '../common/FoldContainer';
import SectionTitle from '../common/SectionTitle';
import scheduleDetails from '../../data/scheduleDetails';
import { newSpeakers } from '../../data/speakers';
import speakers from '../../data/speakers';
import speakersMobile from '../../data/speakersMobile';
import users from '../../data/users';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import UserBadge from '../common/UserBadge.jsx';
import CallsForProposals from '../CallForProposalsSection/CallsForProposals';

const HeaderBox = styled.div`
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  @media screen and (max-width: 1250px) {
    display: block;
  }
`;

const TimeBox = styled.div`
  width: 25%;
  background: #f6b217;
  color: #fff;
  padding: 55px;
  font-weight: bold;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
  @media screen and (min-width: 550px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
  }
  @media screen and (min-width: 1100px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px 30px;
  }
`;

const ModeratorBox = styled.div`
  width: 75%;
  color: #000;
  background: #fcf6b5;
  @media screen and (max-width: 1250px) {
    width: 100%;
  }
  padding: 10px 20px;
`;

const ModeratorTitle = styled.span`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 33px;
  color: #303030;
`;

const IconText = styled.span`
  position: relative;
  top: 1px;
  color: #fff;
  margin-left: 10px;
  font-size: 18px;
`;

const EventBox = styled.div`
  padding: 15px;
`;

const ModerationDivider = styled.div`
  height: 1px;
  background-color: rgba(246, 178, 23, 0.58);
  margin-top: 10px;
`;

const TopicText = styled.div`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #414042;
`;

const TopicList = styled.div`
  padding: 30px 5rem;
  height: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  ${'' /* #speakers-mobile {
    display: none;
  } */}
  ${'' /* @media screen and (max-width: 1200px) {
    #speakers-mobile {
      display: block;
    }
    #speakers-desktop {
      display: none;
    }
  } */}
  @media screen and (max-width: 550px) {
    padding: 15px 2rem;
  }
  @media screen and (min-width: 550px) {
    width: 100%;
    ${'' /* max-width: fit-content; */}
  }
`;

const ConferenceTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: ${({ doubleLine, isMobile }) =>
    doubleLine === 'true' ? '-14px' : isMobile ? '10px' : '-14px'};
  @media screen and (max-width: 1200px) {
    font-size: 13px;
  }
`;

const ModeratorContainer = styled.div`
  padding: 10px 0 0 0;
`;

const SignOffText = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  margin-top: 20px;
`;

const SpeakerImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin-right: 10px;
  @media screen and (max-width: 550px) {
    width: 45px;
    height: 45px;
  }
`;

const SpeakerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin: 1.5rem 0;
  @media screen and (max-width: 930px ) {
    grid-template-columns: 1fr;
    grid-template-rows: 120px auto;
    margin: 1rem 0;
  }
`;

const SpeakerNameAndImage = styled.div`
  display: flex;
  align-items: center;
`

const SpeakerLeft = styled.div`
  display: grid;
  grid-template-columns: 120px 280px;
  grid-template-rows: 1fr;
  @media screen and (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  align-items: center
`;

const SpeakerText = styled.div`
  position: relative;
  top: 1px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
`;

const SpeakerTimeText = styled.div`
  position: relative;
  top: 1px;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  width: fit-content;
  @media screen and (max-width: 930) {
    textAlign: center
  }
`;

const SpeakerCont = ({ avatar, name, time, topic, topic2 }) => {
  return (
    <SpeakerContainer>
      <SpeakerLeft>
        <SpeakerText>{time}</SpeakerText>
        <SpeakerNameAndImage>
          <SpeakerImage src={avatar} alt="" />
          <SpeakerText style={{ color: '#F6B217' }}>{name}</SpeakerText>
        </SpeakerNameAndImage>
      </SpeakerLeft>
      <SpeakerText>{topic}{topic2 ? ':' : ''} {topic2 && <span style={{ 'fontWeight': 500 }}>{topic2}</span>}</SpeakerText>
    </SpeakerContainer>
  )
};

const ScheduleSection = () => {
  return (
    <FoldContainer>
      <SectionTitle backgroundText="schedule">Conference Schedule</SectionTitle>
      <HeaderBox>
        <TimeBox>
          {scheduleDetails.map((el) => (
            <EventBox key={el.text}>
              <img src={el.img} width="30px" height="30px" alt="img" />
              <IconText>{el.text}</IconText>
            </EventBox>
          ))}
        </TimeBox>
        <ModeratorBox>
          <ModeratorTitle>
            Moderators:
            <ModeratorContainer>
              {users.map((user) => {
                return (
                  <UserBadge
                    key={user.name}
                    text={user.name}
                    location={user.location}
                    imageSrc={user.avatar}
                    isPopUp="true"
                    userTitle={user.title}
                    about={user.about}
                    program={user.program}
                    twitter={user.twitter}
                    moderator
                    title={user.title}
                  />
                );
              })}
            </ModeratorContainer>
            <ModerationDivider />
            <TopicText>Introduce the format, agenda and method for questions by chat</TopicText>
          </ModeratorTitle>
        </ModeratorBox>
      </HeaderBox>
      <TopicList>
        <div style={{ 'maxWidth': '940px', margin: '0 auto', position: 'relative' }}>
          <div style={{ display: 'flex', justifyItems: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <SpeakerText>7 PM CST - Door Open</SpeakerText>
            <SpeakerTimeText style={{ margin: '2rem 0' }}>7:05 PM CST - Moderators Introduction</SpeakerTimeText>
          </div>
          <div>
            {newSpeakers.map((speaker) => {
              return (
                <div style={{ height: 'fit-content' }} id="speakers-desktop">
                  <SpeakerCont key={speaker.name} avatar={speaker.avatar} name={speaker.name} time={speaker.time} topic={speaker.topic} topic2={speaker.topic2} />
                </div>
              )
            })}
          </div>
        </div>
        {/* <Row style={{ height: 'fit-content' }} id="speakers-desktop">
          {speakers.map((speaker) => {
            return (
              <Col key={speaker.topic} xs={12} sm={12} md={6}>
                <ConferenceTitle doubleLine={speaker.topic.length > 63 ? 'true' : 'false'}>
                  {speaker.topic}
                </ConferenceTitle>
                <br />
                {
                  <>
                    {speaker?.users?.map((user) => {
                      return (
                        <UserBadge
                          key={user.name}
                          text={user.name}
                          imageSrc={user.avatar}
                          page="conference-list"
                          userTitle={user.title}
                          about={user.about}
                          program={user.program}
                          twitter={user.twitter}
                          isPopUp="true"
                        />
                      );
                    })}
                  </>
                }
                {speaker.position ? <SignOffText>{'Sign- Off'}</SignOffText> : ''}
              </Col>
            );
          })}
        </Row> */}
        {/* <Row style={{ height: 'fit-content' }} id="speakers-mobile">
          {speakersMobile.map((speaker) => {
            return (
              <Col key={speaker.topic} xs={12} sm={12} md={12}>
                <ConferenceTitle isMobile="true">{speaker.topic}</ConferenceTitle>
                {
                  <>
                    {speaker?.users?.map((user) => {
                      return (
                        <UserBadge
                          key={user.name}
                          text={user.name}
                          imageSrc={user.avatar}
                          page="conference-list"
                          userTitle={user.title}
                          about={user.about}
                          program={user.program}
                          twitter={user.twitter}
                          isPopUp="true"
                          group={user.group}
                          isMobile="true"
                        />
                      );
                    })}
                  </>
                }
              </Col>
            );
          })}
        </Row> */}
      </TopicList>
      <CallsForProposals />
    </FoldContainer>
  );
};

export default ScheduleSection;
