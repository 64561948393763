import React, { useState, useEffect, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import twitter from '../../assets/twitter.svg';
import styled from 'styled-components';

const BadgeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: #303030;
  position: relative;
  top: -5px;
  margin: 20px 0;
  .popover-header {
    background-color: #fff !important;
    font-size: 13px;
  }
  .popover {
    width: 900px !important;
  }
`;

const BadgeImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
  @media screen and (max-width: 550px) {
    width: 35px;
    height: 35px;
  }
`;

const BadgeImageBox = styled.div`
  cursor: pointer;
`;

const ConfBadgeText = styled.div`
  cursor: pointer;
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  width: 90%;
  color: ${({ page }) => (page === 'conference-list' ? '#F6B217' : '#000')};
  font-size: ${(props) => (props.page === 'conference-list' ? '14px' : '17px')};
  @media screen and (max-width: 550px) {
    font-size: ${(props) => (props.page === 'conference-list' ? '12px' : '17px')};
  }
`;

const PopoverTitleName = styled.span`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-transform: capitalize;
  color: #f6b217;
  margin-right: 12px;
`;

const PopoverAboutText = styled.span`
  font-style: normal;
  font-size: 12px;
  color: #6e6e6e;
`;

const PopoverBoldText = styled.strong`
  font-size: 12px;
`;

const TwitterLink = styled.a`
  font-size: 12px;
  text-decoration: none;
  font-weight: bolder;
  &:hover {
    text-decoration: none;
  }
`;

const PopoverProgramText = styled.span`
  font-style: normal;
  font-size: 12px;
  color: #6e6e6e;
`;

const UserBadge = ({ imageSrc, text, location, moderator, title, ...props }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  return (
    <BadgeContainer ref={ref} onClick={props.isPopUp ? handleClick : () => {}}>
      <Overlay
        show={show}
        target={target}
        placement="bottom-start"
        container={ref.current}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Title as="h3" id="popover-title">
            <PopoverTitleName>{text}</PopoverTitleName>
            {props.userTitle}
          </Popover.Title>
          <Popover.Content>
            <PopoverBoldText>{text}</PopoverBoldText>
            <PopoverAboutText>&nbsp; {props.about}</PopoverAboutText>
            <br />
            {props.program ? (
              <>
                <PopoverBoldText>Program:</PopoverBoldText>{' '}
                <PopoverProgramText>{props.program}</PopoverProgramText>
              </>
            ) : (
              ''
            )}
            <br />
            {props.twitter ? (
              <>
                <img src={twitter} alt="twitter" width="15px" height="15px" />: &nbsp;
                <TwitterLink href={`https://twitter.com/${props.twitter}`} target="_blank">
                  {props.twitter}
                </TwitterLink>
              </>
            ) : (
              ''
            )}
          </Popover.Content>
        </Popover>
      </Overlay>
      <BadgeImageBox onClick={props.isPopUp ? handleClick : () => {}}>
        <BadgeImage src={imageSrc} />
      </BadgeImageBox>
      <ConfBadgeText {...props}>
        {text}{moderator ? `, ${title}` : ''} {location ? `(${location})` : ''}
      </ConfBadgeText>
    </BadgeContainer>
  );
};

export default UserBadge;
