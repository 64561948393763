import React from 'react';
import styled from 'styled-components';
import CustomButton from '../common/CustomButton';
import IconAndText from '../common/IconAndText';
import eventDetails from '../../data/eventDetails';

interface Props {}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcf6b5;
  width: 100%;
  padding: 50px 70px;
  margin: 40px 0 0 0;
  box-shadow: 8px 8px 0 rgb(214, 208, 208);
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
  @media screen and (min-width: 550px) {
    padding: 40px;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  @media screen and (max-width: 550px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const EventDetailsCard: React.FC<Props> = () => {
  return (
    <Container>
      <IconsContainer>
        {eventDetails.map((el) => (
          <IconAndText key={el.text} icon={el.img} text={el.text} />
        ))}
      </IconsContainer>
      <a href="/#register">
        <CustomButton buttonType="primary">Register Now</CustomButton>
      </a>
    </Container>
  );
};

export default EventDetailsCard;
