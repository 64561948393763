import React, { useState } from 'react';
// import { navigate } from '@reach/router';
import FoldContainer from '../common/FoldContainer';
import SectionTitle from '../common/SectionTitle';
// import Form from 'react-bootstrap/Form';
// import CustomButton from '../common/CustomButton';
import styled from 'styled-components';
// import * as yup from 'yup';
// import { Form as FormikForm, Formik, ErrorMessage, Field } from 'formik';
// import Axios from 'axios';
// import successIcon from '../../assets/success_icon.svg';
// import errorIcon from '../../assets/error_icon.png';

// const FormTextArea = styled(Form.Control)`
//   width: 100%;
//   height: 100px;
//   margin-bottom: 10px;
//   padding: 6px 12px;
//   border: 1px solid #ced4da;
//   border-radius: .25rem;
//   resize: none;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #495057;
//   &:focus {
//     color: #495057;
//     background-color: #fff;
//     border-color: #80bdff;
//     outline: 0;
//     box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
//   }
// `;

// const FormContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
// `;

// const ErrorMessageStyle = styled.p`
//   color: red !important;
//   font-size: 14px;
//   margin-bottom: 0;
// `;

// const ErrorMessageTextAreaStyle = styled.p`
//   color: red !important;
//   font-size: 14px;
//   margin-bottom: -21px;
// `;

// const ErrorMessageLabelContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: auto;
// `;

// const FormInput = styled(Form.Control)`
//   width: 100%;
//   margin-bottom: 10px;
// `;

// const FormGroup = styled(Form.Group)`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   width: 500px;
//   @media screen and (max-width: 550px) {
//     width: 300px;
//   }
// `;

// const AsyncContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   h4 {
//     margin-bottom: 15px;
//   }
//   img {
//     margin-bottom: 10px;
//     height: 60px;
//   }
//   p {
//     line-height: 30px;
//   }
//   @media screen and (max-width: 550px) {
//     align-items: center;
//     p {
//       text-align: center;
//     }
//   }
// `;

// const FormikFormContainer = styled(FormikForm)`
//   display: flex;
//   flex-direction: column;
// `;

// const SpaceCont = styled.div`
//   padding: 6px;
// `

const StyledLink = styled.a`
  outline: none;
  underline: none;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px!important;
  font-family: Poppins, sans - serif;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  color: white;
  background-color: #F6B217;
  &:link,
  &:visited {
    text-decoration: none;
    color: white;
  }
`

const RegisterSection = () => {
  // const [isLoading, setIsLoading] = useState(false);
  // const [success, setSuccess] = useState(null);
  // const [errorMsg, setErrorMsg] = useState('Please refresh and try again!');

  // const isBrowser = () => typeof window !== 'undefined';

  // const initialValues = {
  //   name: '',
  //   email: '',
  //   position: '',
  //   institution: '',
  //   topic: '',
  // };

  // const validationSchema = yup.object({
  //   name: yup.string().required('Required'),
  //   email: yup
  //     .string()
  //     .email('Please enter a work email')
  //     .required('Required')
  //     .test('is-work-email', 'Please enter a work email', (value) => {
  //       if (value) {
  //         return (
  //           (value.includes('gmail') ||
  //             value.includes('yahoo') ||
  //             value.includes('outlook') ||
  //             value.includes('aim') ||
  //             value.includes('aol')) === false
  //         );
  //       }
  //     }),
  //   position: yup.string().required('Required'),
  //   institution: yup.string().required('Required'),
  //   topic: yup.string().max(280, 'Must be 280 characters or less'),
  // });

  // const handleSubmit = (data, helpers) => {
  //   setIsLoading(true);
  //   Axios.post('https://giqohj640f.execute-api.us-east-2.amazonaws.com/staging/mailchimp/ishi', {
  //     ...data,
  //   })
  //     .then(({ data }) => {
  //       console.log({ data });
  //       if (!data.success) {
  //         setSuccess(false);
  //       }
  //       setSuccess(true);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       if (err.response.data.errorMessage === 'Error while adding user to list') {
  //         setErrorMsg(`You have already registered with that email address. Thank you.`);
  //       }
  //       setSuccess(false);
  //       setIsLoading(false);
  //     });
  // };

  // if (success) {
  //   return (
  //     <FoldContainer>
  //       <SectionTitle backgroundText="register">Register Here to Attend</SectionTitle>
  //       <AsyncContainer>
  //         <img src={successIcon} alt="success icon" />
  //         <h4>Successfully registered.</h4>
  //         <p>Thank You!</p>
  //       </AsyncContainer>
  //     </FoldContainer>
  //   );
  // }

  // if (success === false) {
  //   return (
  //     <FoldContainer>
  //       <SectionTitle backgroundText="register">Register Here to Attend</SectionTitle>
  //       <AsyncContainer>
  //         <img src={errorIcon} alt="error icon" />
  //         <h4>There was an error!</h4>
  //         <p>{errorMsg}</p>
  //         <CustomButton
  //           onClick={() => {
  //             navigate('/#register');
  //             isBrowser() && window.location.reload('/#register');
  //           }}
  //         >
  //           Retry
  //         </CustomButton>
  //       </AsyncContainer>
  //     </FoldContainer>
  //   );
  // }

  return (
    <FoldContainer>
      <SectionTitle backgroundText="register">Register Here to Attend</SectionTitle>
      {/* <FormContainer>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, values, dirty, isValid, errors }) => (
            <FormikFormContainer>
              <FormGroup>
                <div>
                  <ErrorMessageLabelContainer>
                    <Form.Label>Full Name</Form.Label>
                    <ErrorMessage name="name">
                      {(msg) => <ErrorMessageStyle>{msg}</ErrorMessageStyle>}
                    </ErrorMessage>
                  </ErrorMessageLabelContainer>
                  <Field
                    as={FormInput}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    type="text"
                    placeholder="Enter name"
                  />
                </div>
                <ErrorMessageLabelContainer>
                  <Form.Label>Work email address</Form.Label>
                  <ErrorMessage name="email">
                    {(msg) => <ErrorMessageStyle>{msg}</ErrorMessageStyle>}
                  </ErrorMessage>
                </ErrorMessageLabelContainer>
                <Field
                  as={FormInput}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  type="email"
                  placeholder="Enter work email"
                />
                <ErrorMessageLabelContainer>
                  <Form.Label>Current Position</Form.Label>
                  <ErrorMessage name="position">
                    {(msg) => <ErrorMessageStyle>{msg}</ErrorMessageStyle>}
                  </ErrorMessage>
                </ErrorMessageLabelContainer>
                <Field
                  as={FormInput}
                  name="position"
                  value={values.position}
                  onChange={handleChange}
                  type="text"
                  placeholder="Current position"
                />
                <ErrorMessageLabelContainer>
                  <Form.Label>Institution</Form.Label>
                  <ErrorMessage name="institution">
                    {(msg) => <ErrorMessageStyle>{msg}</ErrorMessageStyle>}
                  </ErrorMessage>
                </ErrorMessageLabelContainer>
                <Field
                  as={FormInput}
                  name="institution"
                  value={values.institution}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter institution"
                />
                <SpaceCont />
                <FormTextArea
                  as="textarea"
                  name="topic"
                  value={values.topic}
                  onChange={handleChange}
                  type="text"
                  placeholder="Please submit topic if you would like to speak (280 characters or less)"
                />
                {errors.topic && <ErrorMessageTextAreaStyle>{errors.topic}</ErrorMessageTextAreaStyle>}
              </FormGroup>
              <br />
            </FormikFormContainer>
          )}
        </Formik>
      </FormContainer> */}
      <StyledLink
        href='https://18kb8w19g2g.typeform.com/to/o3yL5baQ'
        target="_blank" rel="noopener noreferrer"
      >
        Register Now
      </StyledLink>
    </FoldContainer>
  );
};

export default RegisterSection;
