import archimydesLogo from '../assets/archimydes_logo.svg';
import accurbeatLogo from '../assets/accurbeat_logo.png';
import ishiLogo from '../assets/ishi_logo.png';

const partnersData = [
  {
    img: accurbeatLogo,
    alt: 'accurbeat logo',
    uri: 'https://www.accurkardia.com/',
  },
  {
    img: ishiLogo,
    alt: 'ishi logo',
    uri: 'https://ishi.health/',
  },
  // {
  //   img: archimydesLogo,
  //   alt: 'archimydes logo',
  //   uri: 'https://archimydes.dev/',
  // },
];

export default partnersData;
