import calendarIcon from '../assets/calendar.svg';
import clockIcon from '../assets/clock.svg';

const eventDetails = [
  {
    img: calendarIcon,
    text: 'February 16th, 2023',
  },
  {
    img: clockIcon,
    text: '7-9 PM CST',
  },
];


export default eventDetails;
