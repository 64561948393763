export const keyQuestions = [
  'Why are cardiology graduates choosing not to pursue a career in Heart Failure?',
  'Is the construct of contemporary training programs sufficient to prepare the Heart Failure workforce of the future?',
  'What should the future of Heart Failure training look like?',
  'What does the community need for the Heart Failure Specialist that needs incorporated into training?'
]

export const objectives = [
  'Create a forum to discuss these problems.',
  'Allow a wide variety of constituents to propose solutions.',
  'Debate future directions.'
]
