import van from '../assets/van.png';
import jason from '../assets/jason.png';
import ibrahim from '../assets/ibrahim.png';
import sanjiv from '../assets/sanjiv.png';
import mitch from '../assets/mitch.png';
import reza from '../assets/reza.png';
import ryan from '../assets/ryan.png';
import sinha from '../assets/sinha.png';
import ajay from '../assets/ajay.png';
import michelle from '../assets/michelle.png';
import jane from '../assets/jane.png';
import anu from '../assets/anu.png';
import susie from '../assets/susie.png';
import garan from '../assets/garan.png';
import rob from '../assets/rob.png';
import quentin from '../assets/quentin.png';
import davis from '../assets/davis.png';
import kavita from '../assets/kavita.png';
import greg from '../assets/greg.png';
import tariq from '../assets/tariq.png';
import eiran from '../assets/eiran.png';

const speakersMobile = [
  {
    topic: 'Leadership Principles from 30,000 Feet',
    users: [
      {
        name: 'Dr. Harriette GC Van Spall',
        avatar: van,
        title: 'MD, MPH, FRCPC',
        about: `is a cardiologist and scientist in the Knowledge Translation and Heart Failure programs at the Population Health Research Institute. She leads clinical trials and big data analyticsin heart failure.Her research is supported primarily by the Canadian Institutes of Health Research (CIHR), the Heart and Stroke Foundation, and Ontario's Ministry of Health and Long-Term Care. She mentors postgraduate research fellows, graduate students, and medical residents. She has garnered more than $4.0 million in research funding, has won several research awards, and has published her work in high-impact medical journals, including JAMA, Lancet, JACC,Circulation, European Heart Journal,and Annals of Internal Medicine. She is a member of the College of Reviewers at CIHR and serves as an editorial board member orassociate editor at several journals.`,
        program: 'McMaster University, Ontario, Canada',
        twitter: '@hvanspall',
        group: 'true',
      },
      {
        name: 'Dr. Jason N. Katz',
        avatar: jason,
        title: 'MD, MHS, FAHA',
        about: ` is an Associate Professor of Medicine at Duke University, Co-Director of the Cardiac Intensive Care Unit and Mechanical Circulatory Support Program, and Director of Cardiovascular Critical Care for the Duke University Health System.  He served in similar roles for over a decade at the University of North Carolina. Dr. Katz is currently Chair of the American Heart Association’s Acute Cardiac Care Committee, Executive Steering Committee member of the Critical Care Cardiology Trials Network, and Principal Investigator for the ongoing ARIES HM3 Study. He is an Associate Editor for the European Heart Journal, and Editorial Board Member for the Journal of Heart & Lung Transplantation and the European Heart Journal – Acute Cardiovascular Care. His research and clinical interests focus on improving process and outcomes for critically ill cardiac patients, defining best practice for patients with durable LVADs, and demystifying intensive care for the community at large.`,
        program: ' Duke University Medical Center, Durham, NC',
        twitter: '@JasonKatzMD',
        group: 'true',
      },
    ],
  },
  {
    topic: 'How to bridge clinical care, investigation, and innovation for HFpEF programs',
    users: [
      {
        name: 'Dr. Sanjiv J. Shah',
        avatar: sanjiv,
        title: 'MD',
        about: ` is the Neil Stone, MD Professor in the Division of Cardiology, Department of Medicine; Director of the T1 Center for Cardiovascular Therapeutics; and Director of the Heart Failure with Preserved Ejection Fraction Program at Northwestern University Feinberg School of Medicine. Dr. Shah’s clinical expertise and research focuses on heart failure with preserved ejection fraction (HFpEF), which is one of the most prevalent cardiovascular diseases and for which there are few proven treatments. Sanjiv is also the Director at Institute for Augmented Intelligencein Medicine - Center for Deep Phenotyping and Precision Therapeutics.`,
        program: 'Northwestern University Feinberg School of Medicine, Chicago, IL',
        twitter: '@HFpEF',
      },
    ],
  },
  {
    topic: 'How to generate heart failure career interest for CV fellows',
    users: [
      {
        name: 'Dr. Nosheen Reza',
        avatar: reza,
        title: 'M.D., FACC',
        about: `is an advanced heart failure and transplant cardiologist and translational researcherat the University of Pennsylvania. Shecompleted her internal medicine residency at the Massachusetts GeneralHospitaland her cardiology trainingat the University of Pennsylvania. Dr. Reza joined the Penn faculty in July 2020 and practices with an emphasis on genetic cardiomyopathies. In addition to her academic work,Dr. Reza is passionate about supporting the careers of women and trainees in cardiovascular medicine andhas published on topics ranging fromestablishing women in cardiology groups in the workplace,supporting cardiology trainees during early parenthood,creating novel women's cardiovascular health curricula, and therepresentation of women cardiologists in research.`,
        program: 'University of Pennsylvania, Philadelphia, PA',
        twitter: '@noshreza',
      },
    ],
  },
  {
    topic: 'How to engineer and resource teams around cardiogenic shock',
    users: [
      {
        name: 'Dr. Shashank S. Sinha',
        avatar: sinha,
        title: 'MD, MSc, FACC',
        about: `is an advanced heart failure and transplant cardiologist with a specialized clinical and research focus in critical care cardiology. He currently serves as the Medical Director of the Cardiac Intensive Care Unit and the Director of the Cardiovascular Critical Care Research Program at Inova Heart and Vascular Institute / Inova Fairfax Medical Campus. Shashank is extensively published, and has more than 50 peer-reviewed publications in top-tier journals. The primary focus of his research is to understandand improve the quality of care for patients diagnosed with or experiencing cardiac critical illness, including cardiac arrest, advanced heart failure (including those requiring temporary and durable mechanical circulatory support), and cardiogenic shock. He serves asthe Principal Investigator for the Inova Cardiac Intensive Care Unit Registry and co-Principal Investigator for the Inova Cardiogenic Shock Registry. He is also the site Principal Investigator for the Cardiogenic Shock Working Group Multicenter Registry and the Critical Care Cardiology Trials Network Registry (where he serves on the Executive Steering Committee). Shashank served asone of three inaugural section editors of the JACC Fellows-in-Training and Early Career Page and is currently an Associate Editor for Journal of Cardiac Failure and social media editor for JACC Heart Failure. He is a Fellow of the American College of Cardiology and a member of the American Heart Association and Heart Failure Society of America. Shashank is proud to call the greater DC metropolitan area his home. In his spare time, he loves to read, run, hike, travel, watch shows at the Kennedy Center, and spend time with family and friends.`,
        program: 'Inova Heart and Vascular Institute, Falls Church, Virginia',
        twitter: '@ShashankSinhaMD',
      },
    ],
  },
  {
    topic: 'How to create virality with branding',
    users: [
      {
        name: 'Dr. Michelle Kittleson',
        avatar: michelle,
        title: 'MD, PhD',
        about: `is Director of Education in Heart Failure and Transplantation, Director of Heart Failure Research, and Professor of Medicine at the Smidt Heart Institute, Cedars-Sinai. She received her undergraduate degree from Harvard and medical degree from Yale. She completed residency at Brigham and Women's Hospital and cardiology fellowship at Johns Hopkins, where she received a PhD in Clinical Investigation. She is Deputy Editor of the Journal of Heart and Lung Transplantation, on several Guideline Writing Committees for the American College of Cardiology/American Heart Association and is the Co Editor-in-Chief for the ACC Heart Failure Self-Assessment Program. She has a passion for medical narratives with essays in New England Journal of Medicine, Annals of Internal Medicine, and JAMA Cardiology and poems in JAMA and Annals of Internal Medicine`,
        program: 'Smidt Heart Institute, Cedars-Sinai Medical CenterLos Angeles, CA',
        twitter: '@MKittlesonMD',
      },
    ],
  },
  {
    topic: 'How to lead your surgical and cardiology partners and keep the team aligned',
    users: [
      {
        name: 'Dr. Susan M. Joseph',
        avatar: susie,
        title: 'MD',
        about: `is board certified by the American Board of Internal Medicine in Cardiovascular Disease, and Advanced Heart Failure and Transplant Cardiology. She specializes in the treatment and management of patients with heart failure. Her areas of clinical interests include heart failure, heart transplant, and left ventricular assist device therapy. Her research interests include improving outcomes in patients with mechanical devices in heart failure, innovative device therapy in heart failure, hemodynamics and cardiogenic shock, and assessing frailty in heart failure populations. Dr. Joseph studied medicine at the University of Michigan Medical School in Ann Arbor, Michigan. She completed her residency in internal medicine at Washington University School of Medicine in St. Louis, Missouri where she went on to complete fellowship training in Cardiology, and Advanced Heart Failure and Cardiac Transplantation, as well as serving there as Assistant Professor and Medical Director of the HFpEF clinic. Dr Joseph has published extensively in leading journals as well as several book chapters. She has served as Associate Editor for the Journal of Cardiac Failure as well as Circulation HF. Dr. Joseph currently serves as the Section Chief of Advanced Heart Failure, Transplant, and Mechanical Circulatory Support at the University of Maryland.`,
        program: 'University of Maryland, Baltimore, MD',
        twitter: '@SusanJosephMD',
      },
    ],
  },
  {
    topic: 'How to direct a heart failure fellowship program',
    users: [
      {
        name: 'Dr. Anu Lala',
        avatar: anu,
        title: 'MD',
        about: `serves as the Director of Heart FailureResearch and as Data Coordinating Center leadership for the NHLBI Cardiothoracic Surgery Network. Dr. Lala also leads the fellowship program inAdvanced Heart Failure and Transplant. She has authored over 75 peer reviewed scientific publications and is the principal investigator for a numberof clinical trials in heart failure. Dr. Lala serves on local and national committees in the American Heart Association, American College of Cardiology, Heart Failure Society of America among others, devoted to advanced heart disease. In 2016, she was named the American Heart Association Young Professionals Society Honoree for her service and commitment to education and promoting cardiovascular health awareness. In 2020, she was recognized with the Proctor H. Harvey Teaching Award by the American College of Cardiology which honors a promising young memberof the College who has distinguished herself by dedication and skill in teaching, and to stimulate continued careers in education.`,
        program: 'The Mount Sinai Hospital New York, NY',
        twitter: '@dranulala',
      },
    ],
  },
  {
    topic: 'How to be a principle investigator that truly leads',
    users: [
      {
        name: 'Dr. Rob Mentz',
        avatar: rob,
        title: 'MD, FHFSA, FACC, FAHA',
        about: `is an Associate Professor of Medicine and Chief of the Heart Failure Section at Duke University and the Associate Program Director - Duke Cardiovascular Disease Fellowship. He is the Editor-in-Chief of the Journal of Cardiac Failure – the official journal of the Heart Failure Society of America. He is the Principal Investigator or Clinical Lead for a number of priorstudies (EXSCEL, PRIME-HF, PROVIDE-HF, VICTORIA Registry) and ongoing clinical trials including TRANSFORM-HF (NHLBI-funded pragmatic trial of diuretics in HF), PARAGLIDE-HF (sacubitril/valsartan in acute HFpEF), and HEART-FID (IV iron in HFrEF).`,
        program: 'Duke University Medical Center, Durham, NC',
        twitter: '@robmentz',
      },
    ],
  },
  {
    topic: 'How to bridge the bench to the bedside in heart failure.',
    users: [
      {
        name: 'Dr. Kavita Sharma',
        avatar: kavita,
        title: 'MD',
        about: `is an Assistant Professor of Medicine in Advanced Heart Failure/Transplant Cardiology in the Division of Cardiology at the Johns Hopkins University School of Medicine. She is the Director of the Johns Hopkins Heart Failure with Preserved Ejection Fraction Program. She sees patients at The Johns Hopkins Hospital, downtown campus. She holds board certifications in Internal Medicine, Cardiovascular Disease, and Advanced Heart Failure and Transplant Cardiology.`,
        program: 'Johns Hopkins University School of Medicine, Baltimore, MD',
        twitter: '@KSharmaMD',
      },
    ],
  },
  {
    topic: 'How to gain momentum and mature the heart transplant team',
    users: [
      {
        name: 'Dr. Tariq Ahmad',
        avatar: tariq,
        title: 'MD, MPH, FACC',
        about: `is the Medical Director of the Advanced Heart Failure Program at the Yale University School of Medicine and the Yale New Haven Health System. I focus on the care of patients with end stage heart failure, left ventricular assist devices, and cardiac transplantation. My clinical training began at Brigham and Women's Hospital, Harvard Medical School in Boston. During that time, I also received an MPH from the Harvard School of Public Health. Next, I completed a fellowship in general cardiology and advanced heart failure at Duke University Medical Center. I also did a 2 year fellowship in cardiovascular research at the Duke Clinical Research Institute, the worlds largest Academic Research Organization. My research interest is in using data to improve the care of patients with heart failure. I partner with several investigators at Yale to run pragmatic clinical trials across the health system that are aimed at answering foundational questions about the practice of heart failure- one of these is the REVEAL-HF clinical trial (www.reveal-hf.com).`,
        program: 'Yale New Haven Health System, New Haven, CT',
        twitter: '@yaleHFdoc',
      },
    ],
  },
  {
    topic: 'How to use telemedicine as a tool for growing your program',
    users: [
      {
        name: 'Dr. Eiran Z. Gorodeski',
        avatar: eiran,
        title: 'MD, MPH, FACC, FHFSA',
        about: `is Medical Director of the Advanced Heart Failure & Transplant Center and the James and Angela Hambrick Center for Cardiac Recovery, University Hospitals Harrington Heart & Vascular Institute. He also serves as section head of advanced heart failure in the Division of Cardiovascular Medicine and Associate Professor of Medicine at Case Western Reserve University. Dr. Gorodeski has an interest in the medical management of patients with advanced heart failure, including outpatient, inpatient and critical care. He is a clinical expert in cardiac transplantation and left ventricular assist device (LVAD) management. Dr. Gorodeski has published extensively in areas related to heart failure care transitions and the care of older adults with heart failure. In addition to his clinical and research work, Dr. Gorodeski has significant experience in leadership, management and program building in health care settings.Dr. Gorodeski is an Associate Editor at the Journal of Cardiac Failure. He is an active participant in several committees at both the Heart Failure Society of America and the American College of Cardiology.`,
        program: 'University Hospitals Harrington Heart & Vascular Institute, Cleveland, OH',
        twitter: '@EiranGorodeski ',
      },
    ],
  },
  {
    topic: 'How to combat imposter syndrome while developing self-leadership',
    users: [
      {
        name: 'Dr. Nasrien Ibrahim',
        avatar: ibrahim,
        title: 'MD',
        about: `is an advanced heart failure and transplant cardiologist, researcher, and physician activist. She completed her residency and general cardiology fellowship at the University of Cincinnati Medical Center, her fellowship in advanced heart failure and cardiac transplant at the University of Colorado, and finally a fellowship in clinical research at the Massachusetts General Hospital. Her main research and clinical interests lie in the attainment of equity in the implementation ofguideline-directed medical therapies in patients with heart failure and in organ allocation in heart transplant.`,
        program: '(Spring 2021), Inova Heart & Vascular Institute, Fairfax VA',
        twitter: '@DrNasrien',
      },
    ],
  },
  {
    topic: 'How to leverage national / regional leadership roles to benefit local programs',
    users: [
      {
        name: 'Dr. Mitch Psotka',
        avatar: mitch,
        title: 'MD, PhD',
        about: ` is the Chief of Heart Failure and Director of the Infiltrative Cardiomyopathy Center, Inova Fairfax Medical Center, Chair, Heart Failure Society of America Early Career Committee and Co-Chair, Heart Failure Collaboratory, Digital Health Working Group.`,
        program: 'Inova Heart and Vascular Institute,Falls Church, VA',
        twitter: '@mpsotka',
      },
    ],
  },
  {
    topic: 'How to make the hemodynamics lab both clinically valuable and a research tool',
    users: [
      {
        name: 'Dr. Ryan J. Tedford',
        avatar: ryan,
        title: 'MD, FACC, FAHA, FHFSA',
        about: `is a Professor of Medicine and the Chief of Heart Failure at the Medical University of South Carolina. He serves as medical director of the adult heart transplant program and director of the advanced heart failure and transplant fellowship. He received his MD degree from the UT Southwestern Medical School and completed his residency, cardiology fellowship, and advanced heart failure and transplant training at the Johns Hopkins Hospital. Dr. Tedford has published over 125 peer-reviewed original research manuscripts, reviews, editorials and book chapters. His research efforts are focused on hemodynamic assessment of right ventricular functionand its interaction with the pulmonary circulation and left heart. He is a section editor for the Journal of Heart and Lung Transplantation, and serves on the editorial board for Circulation: Heart Failure ̧ CHEST and JACC: Heart Failure.`,
        program: 'Medical University of South Carolina, Charleston, SC ',
        twitter: '@RyanTedfordMD',
      },
    ],
  },

  {
    topic: 'How to break down silos between disciplines',
    users: [
      {
        name: 'Dr. Ajay V. Srivastava',
        avatar: ajay,
        title: 'MD',
        about: ` is an advanced heart failure cardiologist with a special interest in mechanical circulatory support and heart failure population health. His current roles at Scripps include being an integral member of the Cardiogenic Shock, LVAD and ECMO programs as well as being the physician leader for Heart Failure population health at the system level. His clinical interests included the medical management of chronic heart failure, pulmonary venous hypertension, LVAD, cardiac sarcoidosis and exercise hemodynamics evaluation. Dr. Srivastava has a keen interest in delivery and implementation of heart failure therapies across cardiac sub-specialties both at Scripps and across the larger community. Earlier this year, he joined the editorial board of Journal of Cardiac Failure, the official journal of the Heart Failure Society of America (HFSA).`,
        program: 'Scripps Clinic, La Jolla, CA',
        twitter: '@ajaysmd',
      },
    ],
  },
  {
    topic: 'How to generate momentum and funding for team science at your institution',
    users: [
      {
        name: 'Dr. Jane Wilcox',
        avatar: jane,
        title: 'MD, MSc',
        about: `is an Assistant Professor of Medicine and the Chief of the Section ofHeart Failure at Northwestern Medicine.  She is also the Associate Director ofthe   T1   Center   for   Cardiovascular   Therapeutics   at   Northwestern   UniversityFeinberg   School   of   Medicine.   In   addition   to   caring   for   patients   withcardiomyopathy,   advanced   heart   failure/transplants   and   mechanical   assistdevices on the inpatient service, Dr. Wilcox directs the Myocardial RecoveryClinic   for   patients   with   Heart   Failure   with   recovered   Ejection   Fraction(HFrecEF).   She   also   has   expertise   in   muscular   dystrophy   associatedcardiomyopathy and is an integral member of the multidisciplinary musculardystrophy   association   (MDA)   clinic   at   Northwestern.   Dr.   Wilcox’s   researchinterests   include   characterization   of   the   myocardial   substrate   amongcardiomyopathy patients as it relates to myocardial recovery, novel cardiacimaging in genetic cardiomyopathies, early-phase trials of new therapeuticsfor   genetic   cardiomyopathies,   and   improving   patient/caregiver   self-management of left ventricular assist devices (LVADs). Jane serves on theHFSA   research   committee   and   is   an   Associate   Editor   at   Circulation   HeartFailure.`,
        program: 'Northwestern University Feinberg School of Medicine, Chicago, IL',
        twitter: '@Wilcox Heart',
      },
    ],
  },
  {
    topic: 'How to define inspirational mentoring- the mentee perspective',
    users: [
      {
        name: 'Dr. Quentin R. Youmans',
        avatar: quentin,
        title: '',
        about: ` is a second-year fellow-in-training and chief fellow inthe division of cardiology at Northwestern University Feinberg School of Medicine. He completed his medical school, internal medicine, and chief residency at Northwestern. Throughout his graduate and postgraduate training, he has fostered a passion for health equity, addressing the social determinants of health in heart failure, and being a champion for the underrepresented. As an internal medicine resident, he founded and published on a near-peer mentorship program called the Student to ResidentInstitutional Vehicle for Excellence (STRIVE) Program that connects underrepresented minority medical students and residents through mentorship. Dr. Youmans completed formal leadership training through a year-long longitudinal course during his year as chief resident organized through the Center for Leadership at Northwestern University. He also servesas a fellow editor for the Journal of Cardiac Failure and the Journal of Graduate Medical Education.`,
        program: 'McGaw Medical Center of Northwestern University, Chicago, IL',
        twitter: '@QuentinYoumans',
      },
    ],
  },
  {
    topic: 'How to combat team burnout and inspire motivation',
    users: [
      {
        name: 'Dr. A Reshad Garan',
        avatar: garan,
        title: 'MD',
        about: `is an Associate Professor of Medicine at Harvard Medical School and the Section Chief for Advanced Heart Failure and Mechanical Circulatory Support at the Beth Israel Deaconess Medical Center. He earned his medical degree at the Columbia University College of Physicians and Surgeons and completed Internal Medicine, Cardiology, and Advanced Heart Failure and Cardiac Transplantation there as well. He previously served as the Associate Director of the Cardiac Intensive Care Unit and the Medical Director of the Acute Circulatory Support Program at Columbia University Medical Center. In 2019 he was recruited to Beth Israel Deaconess Medical Center to lead the Advanced Heart Failure Section. He has authored more than 100 original peer-reviewed manuscripts and serves as site PI for multiple trials studying device-based therapies in heart failure.`,
        program: 'Beth Israel Deaconess Medical Center, Boston, MA',
        twitter: '@ReshadGaranMD',
      },
    ],
  },
  {
    topic: 'How to organize teams to address health care disparities',
    users: [
      {
        name: 'Dr. Jonathan Davis',
        avatar: davis,
        title: 'MD, MPHS',
        about: `is the inaugural Director of the Heart Failure Program at Zuckerberg San Francisco General Hospital (ZSFG). ZSFG provides acute andoutpatient care for the San Francisco Health Network, which serves one in eight San Franciscans. Dr. Davis's goals are to create and build infrastructural systems and educational outreach programs to best meet the needs of a diverse and historically underserved population. He completed medical school and Internal Medicine Residency at UCSF, Cardiology and Heart Failure Fellowships at Washington University in St. Louis, and was the Medical Director of Mechanical Circulatory Support at Oregon Health and Sciences University prior to being recruited back to UCSF in 2018.`,
        program: 'San Francisco General Hospital, San Francisco, CA',
        twitter: '@JonathanDavisHF',
      },
    ],
  },
  {
    topic: 'How to deal with VAD program growing pains',
    users: [
      {
        name: 'Dr. Greg Macaluso',
        avatar: greg,
        title: 'MD',
        about: ` is a Clinical Assistant Professor of Medicine at the University of Illinois at Chicago. He is Program Director of the Advanced Heart Failure andTransplant Cardiology Fellowship at Advocate Christ Medical Center/University of Illinois at Chicago. He serves as Director of Heart Failure, MCS, and Transplant Cardiology for Heart Care Centers of Illinois.His clinical and research interests include systems to optimize chronic heart failure management, remote hemodynamic monitoring, MCS technology and management, treatment of right ventricular failure, management of biventricular heart failure, pulmonary hypertension and heart failure imaging. Dr. Macaluso has served as local Principal Investigator or Co-Principal Investigator for numerous clinical trials including ENDURANCE-Supplemental, JARVIK II, INNOVATE HF, MOMENTUM 3, Cardiomems Post-Approval Registry, and PROACTIVE-HF.`,
        program: 'Advocate Christ Medical Center, Oak Lawn, IL',
        twitter: '@gmac78',
      },
    ],
  },

  {
    topic: 'Open Forum/Audience Questions',
  },

  {
    topic: 'Sign- Off',
  },
];

export default speakersMobile;
