import React from 'react';
import styled from 'styled-components';

interface Props {
  icon: string;
  text: string;
  color?: string;
}

const Container = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 100px;
  p {
    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #303030;
    margin: 0;
  }
  img {
    margin-right: 20px;
  }
  @media screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
    img {
      margin-right: 0 !important;
    }
  }
  @media screen and (min-width: 550px) {
    margin-right: 20px;
  }
`;

const IconAndText: React.FC<Props> = ({ icon, text }) => {
  return (
    <Container>
      <img alt="calendar icon" src={icon} />
      <p>{text}</p>
    </Container>
  );
};

export default IconAndText;
