import React from 'react';
import Layout from '../components/Layout/Layout';
import FirstFold from '../components/FirstFold/FirstFold';
import AboutSection from '../components/AboutSection/AboutSection';
import PartnersSection from '../components/PartnersSection/PartnersSection';
import Divider from '../components/common/Divider';
import ScheduleSection from '../components/ScheduleSection/ScheduleSection';
import RegisterSection from '../components/RegisterSection/RegisterSection';


export default function Home() {
  return (
    <Layout>
      <FirstFold />
      <div id="about" />
      <AboutSection />
      <Divider />
      <div id="schedule" />
      <ScheduleSection />
      <Divider />
      <PartnersSection />
      <Divider />
      <div id="register" />
      <RegisterSection />
    </Layout>
  );
}
