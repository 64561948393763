import styled from 'styled-components';

const Divider = styled.div`
  border: 2px solid #e2e2e2;
  margin: 0 250px;
  @media screen and (max-width: 550px) {
    margin: 0 30px 40px 30px;
  }
`;

export default Divider;
