import React from 'react';
import styled from 'styled-components';
import bannerImage from '../../../static/banner-image.png';
import heartIcon from '../../assets/heart_icon.svg';

interface Props {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  @media screen and (max-width: 550px) {
    margin-top: 50px;
    margin-bottom: 30px;
  }
`;

const Title = styled.h1`
  width: 80%;
  font-family: Poppins, sans-serif;
  font-style: normal;
  text-align: center;
  font-weight: bolder;
  text-transform: uppercase;
  color: #4e4e4e;
  font-size: 3rem;
  line-height: 70px;
  em {
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    font-style: normal !important;
    font-size: 5rem;
    line-height: 90px;
  }
  @media screen and (max-width: 550px) {
    font-size: 1.5rem;
    line-height: normal;
    em {
      font-size: 1.5rem;
      line-height: normal;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  margin-top: 60px;
`;

const HeartIcon = styled.img`
  vertical-align: middle;
  @media screen and (max-width: 550px) {
    height: 2.5rem;
  }
`;

const FirstFold: React.FC<Props> = () => {
  return (
    <Container>
      <Title>
        <em>Heart Failure Specialty Training</em>
        <br />
        Future of Careers
        <br />
        and Training to Address
        <br />
        the Needs of Patients with
        <br />
        <HeartIcon alt="heart icon" src={heartIcon} />
        Heart Failure
      </Title>
      <Image src={bannerImage} alt="banner" />
    </Container>
  );
};

export default FirstFold;
